.UploadStatus {
  bottom: 1em;
  left: 1em;
  position: fixed;
  right: 1em;
  z-index: 1000;
}

.UploadStatus-progress {
  display: block;
  width: 20em;
}
