.LoginForm {
  background: -moz-linear-gradient(top, rgba(36, 74, 83, 0.9) 0%, rgba(61, 77, 91, 0.9) 100%) !important;
  background: -webkit-linear-gradient(top, rgba(36, 74, 83, 0.9) 0%, rgba(61, 77, 91, 0.9) 100%) !important;
  background: linear-gradient(to bottom, rgba(36, 74, 83, 0.9) 0%, rgba(61, 77, 91, 0.9) 100%) !important;
  bottom: 0;
  filter: progid:dximagetransform.microsoft.gradient(startColorstr='#e6244a53', endColorstr='#e63d4d5b', GradientType=0) !important;
  left: 0;
  padding-top: 3em;
  position: fixed;
  right: 0;
  top: 39px;
}
