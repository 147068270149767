@import url('https://fonts.googleapis.com/css?family=Julius+Sans+One|Jura|Nixie+One|Syncopate|Unica+One|Vollkorn');

/*
  font-family: 'Julius Sans One', sans-serif;
  font-family: 'Jura', sans-serif;
  font-family: 'Unica One', cursive;
  font-family: 'Syncopate', sans-serif;
  font-family: 'Nixie One', cursive;
  font-family: 'Vollkorn', serif;
*/

.App {
  padding-bottom: 4em;
}

.App .pageBody {
  padding-top: 1em;
}

.App .hidden {
  display: none;
}

.App-logo {
  height: 80px;
}

.App-logo * {
  font-family: 'Vollkorn', serif;
}

.App-header {
  background-color: #244a53;
  color: #fff;
  padding: 1em;
}

.AppHeaderTopBanner {
  background-color: #cdd4d3 !important;
  padding-bottom: 0.5em;
  padding-top: 0.5em;
}

.AppHeaderLogoContainer {
  align-items: center;
  display: flex !important;
  justify-content: space-between;
}

.AppHeaderLogoContainer .AppName {
  color: #313131;
  font-family: 'Jura', sans-serif !important;
  font-size: 200%;
}

.App-body {
  background-color: #fff !important;
  min-height: 200px;
}

.Cargo-header {
  color: #313131 !important;
  font-family: 'Jura', sans-serif !important;
  font-size: 1.8em;
  font-weight: lighter !important;
  margin: 1em 0 2em 0 !important;
  text-align: left;
}
