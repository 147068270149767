.PhotoEditor .content {
  position: relative;
}

.PhotoEditor-buttons {
  align-content: space-between;
  display: flex;
  justify-content: center;
}

.PhotoEditor-loader {
  min-height: 10em;
  text-align: center;
}

.PhotoEditor-nextButton,
.PhotoEditor-prevButton,
.PhotoEditor-closeButton {
  position: absolute;
  z-index: 1000;
}

.PhotoEditor-closeButton {
  right: 1em;
  top: 1em;
}

.PhotoEditor-nextButton,
.PhotoEditor-prevButton {
  bottom: 1em;
}

.PhotoEditor-nextButton {
  right: 1em;
}

.PhotoEditor-prevButton {
  left: 1em;
}
