.Stat-City-header {
  color: #313131 !important;
  font-family: 'Jura', sans-serif !important;
  font-size: 1.8em;
  font-weight: lighter !important;
  margin: 1em 0 2em 3.5em !important;
  text-align: left;
}

.Stat-City-chart {
  height: 40vh !important;
  width: 100% !important;
}

.Stat-City-wrapper {
  text-align: center;
  width: 100% !important;
}

.Stat-City-metrics-table td,
th {
  text-align: center !important;
}

td.left-align {
  padding-left: 1.5em !important;
  text-align: left !important;
}

.Stat-City-buttons {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.Stat-City-Data-Wrapper {
  margin: 20px auto 10px auto !important;
  width: 70% !important;
}

.stats-table {
  font-size: 0.9em !important;
  margin: 35px auto 10px auto !important;
  width: 95% !important;
}

.Stat-City-buttons div,
.Stat-City-buttons button,
.Stat-City-buttons input {
  margin: 0.25em !important;
  text-align: left !important;
}

.missing-stats {
  background-color: #f2f2f2 !important;
  line-height: 2em !important;
  text-align: center !important;
}
