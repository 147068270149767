.PhotoList .noItems {
  color: #777;
  margin-bottom: 1.5em;
  text-align: center;
}

.PhotoList-item {
  margin-bottom: 12px;
}

.PhotoList-buttons > .ui.button {
  position: absolute;
  top: 0.75em;
}

.PhotoList-buttons > .ui.button:first-child {
  left: 0.75em;
}

.PhotoList-buttons > .ui.button:last-child {
  right: 0.75em;
}

.PhotoList .ui.image {
  cursor: pointer;
  margin: auto;
  width: auto;
}

.PhotoList .button {
  margin-left: 1em;
}

.PhotoList .ui.menu .item.disabled {
  background-color: #e3e3e3 !important;
  cursor: not-allowed;
}

.PhotoList .ui.menu .item.disabled i.icon {
  color: #93939b !important;
}

.PhotoList .imageContainer {
  min-height: 55px;
}

.PhotoList .imageContainer .ui.dimmer > .content {
  padding: 1.5em;
}
