.QuickStartInput .ui.segment {
  color: #0b0e17;
}

.QuickStartInput .ui.segment .header {
  margin-bottom: 0.25em;
}

.QuickStartInput .ui.segment p {
  background-color: #efefef;
  border-radius: 2px;
  list-style: none;
  padding: 10px;
}

.QuickStartInput .field .input input {
  background-color: #131e29 !important;
  color: #cdd4d3 !important;
}

.QuickStartInput .field .error.input input {
  background-color: #290915 !important;
  color: #d45c64 !important;
}

.ui.QuickStartInput .field .input .icon {
  color: #fff !important;
}

.ui.QuickStartInput .field .input.error .icon {
  color: #b91433 !important;
}
