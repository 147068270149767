@import url(https://fonts.googleapis.com/css?family=Julius+Sans+One|Jura|Nixie+One|Syncopate|Unica+One|Vollkorn);
@import url(https://fonts.googleapis.com/css?family=Julius+Sans+One|Jura|Nixie+One|Syncopate|Unica+One|Vollkorn);
.LoginForm {
  background: linear-gradient(to bottom, rgba(36, 74, 83, 0.9) 0%, rgba(61, 77, 91, 0.9) 100%) !important;
  bottom: 0;
  -webkit-filter: progid:dximagetransform.microsoft.gradient(startColorstr='#e6244a53', endColorstr='#e63d4d5b', GradientType=0) !important;
          filter: progid:dximagetransform.microsoft.gradient(startColorstr='#e6244a53', endColorstr='#e63d4d5b', GradientType=0) !important;
  left: 0;
  padding-top: 3em;
  position: fixed;
  right: 0;
  top: 39px;
}

.Home-roleButton.ui.button {
  margin-bottom: 2em;
}

.Home-itemRibbon.ui.ribbon.label {
  margin-bottom: 1.5em;
}

.Base-label {
  font-size: 13px;
  font-weight: 700;
}

.LoadedFileList {
  text-align: center;
}

.PhotoEditor .content {
  position: relative;
}

.PhotoEditor-buttons {
  align-content: space-between;
  display: flex;
  justify-content: center;
}

.PhotoEditor-loader {
  min-height: 10em;
  text-align: center;
}

.PhotoEditor-nextButton,
.PhotoEditor-prevButton,
.PhotoEditor-closeButton {
  position: absolute;
  z-index: 1000;
}

.PhotoEditor-closeButton {
  right: 1em;
  top: 1em;
}

.PhotoEditor-nextButton,
.PhotoEditor-prevButton {
  bottom: 1em;
}

.PhotoEditor-nextButton {
  right: 1em;
}

.PhotoEditor-prevButton {
  left: 1em;
}

.PhotoList .noItems {
  color: #777;
  margin-bottom: 1.5em;
  text-align: center;
}

.PhotoList-item {
  margin-bottom: 12px;
}

.PhotoList-buttons > .ui.button {
  position: absolute;
  top: 0.75em;
}

.PhotoList-buttons > .ui.button:first-child {
  left: 0.75em;
}

.PhotoList-buttons > .ui.button:last-child {
  right: 0.75em;
}

.PhotoList .ui.image {
  cursor: pointer;
  margin: auto;
  width: auto;
}

.PhotoList .button {
  margin-left: 1em;
}

.PhotoList .ui.menu .item.disabled {
  background-color: #e3e3e3 !important;
  cursor: not-allowed;
}

.PhotoList .ui.menu .item.disabled i.icon {
  color: #93939b !important;
}

.PhotoList .imageContainer {
  min-height: 55px;
}

.PhotoList .imageContainer .ui.dimmer > .content {
  padding: 1.5em;
}

.ui.modal .PhotoViewer-content > .ui.image {
  padding: 0 !important;
}

.PhotoManager-previewModalContent {
  min-height: 100px;
}

.Tools-input {
  margin: 0.5em 0 0.5em 0 !important;
  padding: 0.5em 1em 0.5em 1em !important;
}

.Tools-button {
  margin-top: 0.75rem !important;
  width: 10em !important;
}

.UploadStatus {
  bottom: 1em;
  left: 1em;
  position: fixed;
  right: 1em;
  z-index: 1000;
}

.UploadStatus-progress {
  display: block;
  width: 20em;
}

.Stat-City-header {
  color: #313131 !important;
  font-family: 'Jura', sans-serif !important;
  font-size: 1.8em;
  font-weight: lighter !important;
  margin: 1em 0 2em 3.5em !important;
  text-align: left;
}

.Stat-City-chart {
  height: 40vh !important;
  width: 100% !important;
}

.Stat-City-wrapper {
  text-align: center;
  width: 100% !important;
}

.Stat-City-metrics-table td,
th {
  text-align: center !important;
}

td.left-align {
  padding-left: 1.5em !important;
  text-align: left !important;
}

.Stat-City-buttons {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.Stat-City-Data-Wrapper {
  margin: 20px auto 10px auto !important;
  width: 70% !important;
}

.stats-table {
  font-size: 0.9em !important;
  margin: 35px auto 10px auto !important;
  width: 95% !important;
}

.Stat-City-buttons div,
.Stat-City-buttons button,
.Stat-City-buttons input {
  margin: 0.25em !important;
  text-align: left !important;
}

.missing-stats {
  background-color: #f2f2f2 !important;
  line-height: 2em !important;
  text-align: center !important;
}

.QuickStartInput .ui.segment {
  color: #0b0e17;
}

.QuickStartInput .ui.segment .header {
  margin-bottom: 0.25em;
}

.QuickStartInput .ui.segment p {
  background-color: #efefef;
  border-radius: 2px;
  list-style: none;
  padding: 10px;
}

.QuickStartInput .field .input input {
  background-color: #131e29 !important;
  color: #cdd4d3 !important;
}

.QuickStartInput .field .error.input input {
  background-color: #290915 !important;
  color: #d45c64 !important;
}

.ui.QuickStartInput .field .input .icon {
  color: #fff !important;
}

.ui.QuickStartInput .field .input.error .icon {
  color: #b91433 !important;
}

/*
  font-family: 'Julius Sans One', sans-serif;
  font-family: 'Jura', sans-serif;
  font-family: 'Unica One', cursive;
  font-family: 'Syncopate', sans-serif;
  font-family: 'Nixie One', cursive;
  font-family: 'Vollkorn', serif;
*/

.App {
  padding-bottom: 4em;
}

.App .pageBody {
  padding-top: 1em;
}

.App .hidden {
  display: none;
}

.App-logo {
  height: 80px;
}

.App-logo * {
  font-family: 'Vollkorn', serif;
}

.App-header {
  background-color: #244a53;
  color: #fff;
  padding: 1em;
}

.AppHeaderTopBanner {
  background-color: #cdd4d3 !important;
  padding-bottom: 0.5em;
  padding-top: 0.5em;
}

.AppHeaderLogoContainer {
  align-items: center;
  display: flex !important;
  justify-content: space-between;
}

.AppHeaderLogoContainer .AppName {
  color: #313131;
  font-family: 'Jura', sans-serif !important;
  font-size: 200%;
}

.App-body {
  background-color: #fff !important;
  min-height: 200px;
}

.Cargo-header {
  color: #313131 !important;
  font-family: 'Jura', sans-serif !important;
  font-size: 1.8em;
  font-weight: lighter !important;
  margin: 1em 0 2em 0 !important;
  text-align: left;
}

body {
  font-family: sans-serif;
  margin: 0;
  padding: 0;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-pop {
  box-shadow: 0 0 4px 2px rgba(34, 36, 38, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.border {
  border: 1px solid rgba(34, 36, 38, 0.15) !important;
}

.border-light {
  border: 1px solid #d6d6d6 !important;
}

.border-black {
  border: 1px solid black !important;
}

.border-green {
  border: 1px solid #43a047 !important;
}

.border-blue {
  border: 1px solid blue !important;
}

.border-dashed {
  border: 1px dashed lightgrey !important;
}

.border-l-grey {
  border-left-color: #1a1a1a !important;
}

.border-l-dotted {
  border-left-style: dotted !important;
}

.border-l-2 {
  border-left-width: 2px !important;
}

.round {
  border-radius: 0.28571429rem;
}

.shadow-none {
  box-shadow: none !important;
}

.w-5 {
  width: 5% !important;
}

.w-10 {
  width: 10% !important;
}

.w-20 {
  width: 20% !important;
}

.w-30 {
  width: 30% !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-60 {
  width: 60% !important;
}

.w-75 {
  width: 75% !important;
}

.w-90 {
  width: 90% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.w-20r {
  width: 20rem !important;
}

.w-50r {
  width: 50rem !important;
}

.h-1 {
  height: 1rem !important;
}

.h-2 {
  height: 2rem !important;
}

.h-3 {
  height: 3rem !important;
}

.h-4 {
  height: 4rem !important;
}

.h-5 {
  height: 5rem !important;
}

.h-8 {
  height: 8rem !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.h-20r {
  height: 20rem !important;
}

.h-50r {
  height: 50rem !important;
}

.mh-5r {
  max-height: 5rem !important;
}

.mh-10r {
  max-height: 10rem !important;
}

.mh-15r {
  max-height: 15rem !important;
}

.mh-20r {
  max-height: 20rem !important;
}

.mh-30r {
  max-height: 30rem !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mw-2r {
  max-width: 2rem !important;
}

.mw-7r {
  max-width: 7rem !important;
}

.mw-8r {
  max-width: 8rem !important;
}

.mw-10r {
  max-width: 10rem !important;
}

.mh-100 {
  max-height: 100% !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

.flex {
  display: flex !important;
}

.inline-flex {
  display: inline-flex !important;
}

.flex-shrink {
  flex-shrink: 1 !important;
}

.flex-wr {
  flex-wrap: wrap !important;
}

.f-wrap {
  flex-wrap: wrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.ai-center {
  align-items: center !important;
}

.ai-start {
  align-items: flex-start !important;
}

.ai-baseline {
  align-items: baseline !important;
}

.ai-flex-end {
  align-items: flex-end;
}

.as-center {
  align-self: center !important;
}

.column-gap {
  grid-column-gap: 1rem;
  -webkit-column-gap: 1rem;
          column-gap: 1rem;
}

.jc-start {
  justify-content: start !important;
}

.jc-center {
  justify-content: center !important;
}

.jc-between {
  justify-content: space-between !important;
}

.jc-around {
  justify-content: space-around !important;
}

.jc-even {
  justify-content: space-evenly !important;
}

.jc-end {
  justify-content: flex-end !important;
}

.fd-row {
  flex-direction: row !important;
}

.fd-column {
  flex-direction: column !important;
}

.t-center {
  text-align: center !important;
}

.t-left {
  text-align: left !important;
}

.t-right {
  text-align: right !important;
}

.text-overflow-ellipsis {
  text-overflow: ellipsis !important;
}

.ws-nowrap {
  white-space: nowrap !important;
}

.fw-100 {
  font-weight: 100 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.fs-1 {
  font-size: 1rem !important;
}

.fs-1-25 {
  font-size: 1.25rem !important;
}

.fs-09 {
  font-size: 0.9rem !important;
}

.fs-x-small {
  font-size: x-small !important;
}

.fs-2 {
  font-size: 2rem !important;
}

.fs-medium {
  font-size: medium !important;
}

.fs-large {
  font-size: large !important;
}

.fs-x-large {
  font-size: x-large !important;
}

.fs-xx-large {
  font-size: xx-large !important;
}

.ff-jura {
  font-family: 'Jura', sans-serif !important;
}

.float-right {
  float: right !important;
}

.float-left {
  float: left !important;
}

.absolute {
  position: absolute !important;
}

.relative {
  position: relative !important;
}

.d-block {
  display: block !important;
}

.d-none {
  display: none !important;
}

.bg-none {
  background-color: transparent !important;
}

.bg-clear {
  background-color: #fff !important;
}

.bg-cargo-grey {
  background-color: #cdd4d3 !important;
}

.bg-black {
  background-color: #000 !important;
}

.bg-whitesmoke {
  background-color: whitesmoke !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-light-grey {
  background-color: #e1e1e1 !important;
}

.bg-material-red {
  background-color: #ff1744 !important;
}

.bg-material-blue {
  background-color: #304ffe !important;
}

.bg-material-green {
  background-color: #43a047 !important;
}

.bg-material-purple {
  background-color: #6200ea !important;
}

.bg-material-orange {
  background-color: #e65100 !important;
}

.bg-material-dark {
  background-color: #616161 !important;
}

.bg-material-yellow {
  background-color: #ff3 !important;
}

.c-black {
  color: #313131 !important;
}

.c-green {
  color: green !important;
}

.c-blue {
  color: blue !important;
}

.c-red {
  color: red !important;
}

.c-white {
  color: #fff !important;
}

.light-blue {
  color: #03a9f4 !important;
}

.t-20 {
  top: 20% !important;
}

.t-2r {
  top: 2rem !important;
}

.t-4r {
  top: 4rem !important;
}

.t-5r {
  top: 5rem !important;
}

.t-8r {
  top: 8rem !important;
}

.t-10r {
  top: 10rem !important;
}

.t-11r {
  top: 11rem !important;
}

.l-0 {
  left: 0 !important;
}

.r-0 {
  right: 0 !important;
}

.r-1r {
  right: 1rem !important;
}

.r-2r {
  right: 2rem !important;
}

.r-3r {
  right: 3rem !important;
}

.b-0 {
  bottom: 0 !important;
}

.b-2r {
  bottom: 2rem !important;
}

.b-5r {
  bottom: 5rem !important;
}

.b-10r {
  bottom: 10rem !important;
}

.b-15r {
  bottom: 15rem !important;
}

.b-20r {
  bottom: 20rem !important;
}

.b-25r {
  bottom: 25rem !important;
}

.primary {
  background-color: #2b2b2b !important;
}

.primary-d {
  background-color: #8d8d8d !important;
}

.primary-l {
  background-color: #efefef !important;
}

.fixed {
  position: fixed !important;
}

.l-1r {
  left: 1rem !important;
}

.mtn-3 {
  margin-top: -3rem !important;
}

.ofy-auto {
  overflow-y: auto !important;
}

.tr-hover tr:hover {
  background-color: yellow !important;
}

.pointer {
  cursor: pointer !important;
}

.lh-1-75r {
  line-height: 1.75rem !important;
}

.mb-hover:hover {
  margin-top: -1rem !important;
}

.well {
  box-shadow: inset 2px 2px 1px rgba(0, 0, 0, 0.05);
}

.zi-1 {
  z-index: 1 !important;
}

.lh-1-75 {
  line-height: 1.75rem !important;
}

.lh-1-50 {
  line-height: 1.5rem !important;
}

.lh-1-15 {
  line-height: 1.15rem !important;
}

.border-bottom-red {
  border-bottom: 1px solid red !important;
}

.border-bottom-blue {
  border-bottom: 1px solid blue !important;
}

.border-bottom-lightgrey {
  border-bottom: 1px solid lightgrey;
}

.border-basic {
  border: 1px solid !important;
}

.focus-border:hover {
  border-left: 4px solid lightgray !important;
}

.selected-border {
  border-left: 4px solid #2b2b2b !important;
}

.selected-border-6 {
  border-left: 6px solid #b70201 !important;
}

.border-width-0 {
  border-width: 0 !important;
}

.pulse {
  -webkit-animation: pulse 2s infinite;
          animation: pulse 2s infinite;
  border-radius: 1rem;
  box-shadow: 0 0 0 rgba(243, 9, 9, 0.4);
  cursor: pointer;
}

.pulse:hover {
  -webkit-animation: none;
          animation: none;
}

.red-placeholder::-webkit-input-placeholder {
  color: red !important;
}

.emergency-background {
  background: rgb(226, 28, 29) !important;
  background:
    linear-gradient(
      90deg,
      rgba(226, 28, 29, 1) 12%,
      rgba(255, 255, 255, 1) 19%,
      rgba(255, 255, 255, 1) 82%,
      rgba(237, 29, 29, 1) 91%,
      rgba(255, 0, 0, 1) 100%
    ) !important;
}

.fraud-background {
  background: linear-gradient(to bottom, #f30 -20%, #fff 132%) !important;
}

.first-time-buyer-background {
  background: linear-gradient(to bottom, #21ba44 -20%, #fff 132%) !important;
}

.ellipsis {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(243, 9, 9, 0.4);
  }

  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(243, 9, 9, 0);
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(243, 9, 9, 0);
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(243, 9, 9, 0.4);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(243, 9, 9, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(243, 9, 9, 0);
  }
}

